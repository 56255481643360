@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

@font-face {
  font-family: "Gilory";
  src: url("./assets/font/Gilroy-Bold.ttf");
}

* {
  scroll-behavior: smooth !important;
}

html,
body {
  overflow-x: hidden !important;
}

.ff_montserrat {
  font-family: "Montserrat", sans-serif;
}

.ff_Gilory {
  font-family: "Gilory", sans-serif;
}

.form_box {
  background: rgba(255, 255, 255, 0.02);
  opacity: 0.8;
}

/* .hero_bg {
  background: linear-gradient(113.94deg, #080B11 3.01%, #2C3C5B 52.77%, #5E738E 71.55%, #07C8AA 110.39%);
} */

/* ========================================= */
.translate_box:hover .services_box {
  transform: translateX(10px);
}

.translate_box2:hover .services_box2 {
  transform: translateX(-15px);
}

.scrollBar_Organization::-webkit-scrollbar {
  display: none;
}

.nav_open {
  background: linear-gradient(113.94deg,
      #080b11 3.01%,
      #2c3c5b 52.77%,
      #5e738e 71.55%,
      #07c8aa 110.39%);
  z-index: 50;
}

.animate_loading {
  animation: loading 1.5s ease-in-out alternate infinite;
}

@keyframes loading {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}

.overflow_hidden_preloder {
  overflow: hidden !important;
  position: relative;
}

.nav_links {
  text-decoration: none;
  position: relative;
}

.nav_btn_stroke:hover {
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: white;
}

.text_stroke {
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: white;
}

.nav_links::after {
  content: "";
  position: absolute;
  background-color: white;
  width: 0;
  height: 2px;
  bottom: -3px;
  left: 50%;
  transition: 0.4s;
}

.nav_links:hover::after {
  content: "";
  width: 100%;
  transition: 0.4s;
  transform: translateX(-50%);
}

#back-to-up {
  animation: backtotop 2s ease-in-out infinite alternate;
}

@keyframes backtotop {
  from {
    transform: translateY(-10px);
  }

  to {
    transform: translateY(7px);
  }
}

.approach_lottie div {
  height: auto !important;
  max-width: 400px !important;
}

/* ====================================================== ABOUT ==================================================== */

.about_lootie div {
  width: 100% !important;
  height: 100% !important;
}

/* ======================================================footer==================================================== */
.cloud_1 {
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-name: animatcloud;
}

.cloud_2 {
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-name: animatcloud;
}

@keyframes animatcloud {
  0% {
    margin-left: -100%;
  }

  100% {
    margin-left: 100%;
  }
}

/* hero-sec-animation */

.dots1 {
  background: linear-gradient(113.78deg, #5e738e 27.71%, #07c8aa 113.97%);
  transform: rotate(-9.3deg);
  width: 23px;
  height: 23px;
  border-radius: 50%;
  animation: scaling 3s ease-in-out infinite;
}

.dots2 {
  background: linear-gradient(113.78deg, #5e738e 27.71%, #07c8aa 113.97%);
  transform: rotate(-9.3deg);
  width: 23px;
  height: 23px;
  border-radius: 50%;
  animation: scaling 4s ease-in-out infinite;
}

.dots3 {
  background: linear-gradient(113.78deg, #5e738e 27.71%, #07c8aa 113.97%);
  transform: rotate(-9.3deg);
  width: 23px;
  height: 23px;
  border-radius: 50%;
  animation: scaling 5s ease-in-out infinite;
}

@keyframes scaling {

  0%,
  100% {
    transform: translateZ(0) scale(0.2);
  }

  40%,
  50% {
    transform: translateZ(0) scale(1);
  }
}

.hero_cloud1 {
  animation-duration: 28s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-name: animatcloud;
}

.hero_cloud2 {
  animation-duration: 19s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-name: animatcloud;
}

.hero_cloud3 {
  animation-duration: 25s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-name: animatcloud;
}

@keyframes animatcloud {
  0% {
    margin-left: -100%;
  }

  100% {
    margin-left: 100%;
  }
}

/* ============================================================================================================= */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3e8990;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3e8990;
}

@media (min-width: 800px) {
  .hero_lottie canvas {
    height: 800px !important;
    width: 1000px !important;

  }
}

/* @media (min-width: 300px) and (max-width: 2000px) {
  .hero_lottie div {
    left: -340px;
  }
} */

/* @media (max-width: 1399.98px) {
  .hero_lottie canvas {
    height: auto !important;
    max-width: 1600px;
  }
} */

/* @media (max-width: 1023.98px) {
  .hero_lottie::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
} */

@media (max-width: 320px) {
  .approach_lottie div {
    max-width: 300px !important;
  }
}

@media (min-width: 576px) {
  .approach_lottie div {
    max-width: 600px !important;
  }
}

@media (max-width: 767.98px) {
  .translate_box:hover .services_box {
    transform: translateY(-10px);
  }

  .translate_box2:hover .services_box2 {
    transform: translateY(-15px);
  }
}

@media (max-width: 639.98px) {
  .hero_lottie canvas {
    max-width: 900px;
  }
}

@media (min-width: 768px) {
  .approach_lottie div {
    max-width: 600px !important;
  }

  .nav_open {
    all: unset;
  }
}

@media (min-width: 1300px) {
  .approach_lottie div {
    max-width: 800px !important;
  }
}

@media (min-width: 1535.98px) {
  .px_75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .hero_dot {
    left: 1%;
  }
}

.text-blue {

  color: #07c8aa;
}